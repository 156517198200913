
import { defineComponent } from "vue";
export default defineComponent({
  name: "CustomInput",
  data() {
    return {
      focused: false,
    };
  },
  props: {
    title: String,
    maxChars: Number,
    placeholder: String,
    type: String,
    error: String,
    disabled: { default: false },
    textArea: { default: false },
    validMessage: String,
    modelValue: String,
    prefix: String,
    prefixIcon: String,
    connectLeft: Boolean,
    connectRight: Boolean,
    hideError: { default: false },
  },
  emits: ["update:modelValue"],
  methods: {
    inputEvent(event: any) {
      this.$emit("update:modelValue", event.target.value);
    },
    onClick(event: MouseEvent) {
      (event?.target as HTMLButtonElement).click();
    },
  },
  computed: {
    charCount(): number {
      return (this.modelValue as string).length;
    },
  },
});
