
import {
  ExpandedUser,
  fetchRecentUsers,
  searchUsers,
} from "@/services/adminService";
import UserTemplate from "./UserTemplate.vue";
import CustomButton from "@/components/CustomButton.vue";
import { defineComponent } from "vue";
import { PopoutsModule } from "@/store/modules/popouts";
export default defineComponent({
  name: "Users",
  emits: ["click", "suspendBatch", "unsuspendBatch"],
  components: { UserTemplate, CustomButton },
  data() {
    return {
      users: null as ExpandedUser[] | null,
      searchInput: "",
      timeout: null as number | null,
      checkedUsers: [] as ExpandedUser[],
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    suspendBatch() {
      PopoutsModule.ShowPopout({
        id: "admin-suspend-user-popout",
        component: "AdminSuspendUser",
        data: { users: this.checkedUsers, callback: this.suspendCallback },
      });
    },
    unsuspendBatch() {
      PopoutsModule.ShowPopout({
        id: "admin-unsuspend-user-popout",
        component: "AdminUnsuspendUser",
        data: { users: this.checkedUsers, callback: this.unsuspendCallback },
      });
    },
    suspendCallback(user: ExpandedUser) {
      this.$emit("suspendBatch", user);
      this.checkedUsers = this.checkedUsers.filter((u) => u.id !== user.id);
    },
    unsuspendCallback(data: any) {
      this.$emit("unsuspendBatch", data);
      this.checkedUsers = this.checkedUsers.filter(
        (u) => u.id !== data.user.id
      );
    },
    checkBoxClick(user: ExpandedUser) {
      const existingIndex = this.checkedUsers.findIndex(
        (u) => u.id === user.id
      );
      if (existingIndex >= 0) {
        this.checkedUsers.splice(existingIndex, 1);
        return;
      }
      this.checkedUsers.push(user);
    },
    onInput(event: any) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(
        () => this.searchUser(event.target.value),
        500
      );
    },
    async fetchUsers() {
      this.users = [];
      if (this.searchInput) {
        this.users = await searchUsers(this.searchInput);
        return;
      }
      this.users = await fetchRecentUsers();
    },
    async searchUser(value: string) {
      if (!value) return this.fetchUsers();
      this.users = await searchUsers(value);
    },
    updateUser(user: ExpandedUser) {
      if (!this.users) return;
      const index = this.users?.findIndex((u) => u.id === user.id);
      if (index < 0) return;
      this.users[index] = user;
    },
  },
  computed: {
    suspendChecked() {
      return this.checkedUsers[0].banned;
    },
  },
});
