
import { ExpandedUser, searchUsersByIP } from "@/services/adminService";
import UserTemplate from "./UserTemplate.vue";
import CustomButton from "@/components/CustomButton.vue";
import CustomInput from "@/components/CustomInput.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "SelectedUserPage",
  components: { UserTemplate, CustomButton, CustomInput },
  props: {
    user: {
      type: Object as PropType<ExpandedUser>,
      required: true,
    },
  },
  data() {
    return {
      IPUsers: [] as ExpandedUser[],
    };
  },
  computed: {
    items(): any {
      return [
        {
          name: "Email",
          icon: "alternate_email",
          value: this.user.email,
        },
        {
          name: "User ID",
          icon: "perm_identity",
          value: this.user.id,
        },
        {
          name: "User IP",
          icon: "dns",
          value: this.user.ip,
        },
      ];
    },
    bannerURL(): any {
      if (!this.user.banner) return null;
      return process.env.VUE_APP_NERTIVIA_CDN + this.user.banner;
    },
  },
  async mounted() {
    this.IPUsers = await searchUsersByIP(this.user.id);
    this.IPUsers = this.IPUsers.filter((u) => u.id !== this.user.id);
  },
  methods: {
    openSuspendPopout() {
      PopoutsModule.ShowPopout({
        id: "admin-suspend-user-popout",
        component: "AdminSuspendUser",
        data: { user: this.user, callback: this.suspendCallback },
      });
    },
    openUnsuspendPopout() {
      PopoutsModule.ShowPopout({
        id: "admin-unsuspend-user-popout",
        component: "AdminUnsuspendUser",
        data: { user: this.user, callback: this.unsuspendCallback },
      });
    },
    viewProfile(id: string) {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id },
      });
    },
    suspendCallback() {
      this.$emit("suspended", this.user);
    },
    unsuspendCallback() {
      this.$emit("unsuspend", this.user);
    },
  },
});
