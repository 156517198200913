
import { ExpandedUser } from "@/services/adminService";
import Stats from "./Stats.vue";
import Users from "./Users.vue";
import AdminActions from "./AdminActions.vue";
import SelectedUserPage from "./SelectedUserPage.vue";

import { defineComponent } from "vue";
export default defineComponent({
  components: { Stats, Users, SelectedUserPage, AdminActions },
  data() {
    return {
      selectedUser: null as ExpandedUser | null,
    };
  },
  methods: {
    getUserComponent() {
      return this.$refs.users as InstanceType<typeof Users>;
    },
    getAdminActionsComponent() {
      return this.$refs.adminActions as InstanceType<typeof AdminActions>;
    },
    suspendUser(user?: ExpandedUser, selected = false) {
      if (!user) return;
      const newUser = { ...user, banned: true };
      this.getUserComponent().updateUser(newUser);
      selected && (this.selectedUser = newUser);
      this.getAdminActionsComponent().fetchActions();
    },
    unsuspendUser(user?: ExpandedUser, selected = false) {
      if (!user) return;
      const newUser = { ...user, banned: false };
      this.getUserComponent().updateUser(newUser);
      selected && (this.selectedUser = newUser);
      this.getAdminActionsComponent().fetchActions();
    },
  },
});
