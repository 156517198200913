
import { fetchRecentActions, Action } from "@/services/adminService";
import ActionTemplate from "./ActionTemplate.vue";

import { defineComponent } from "vue";
export default defineComponent({
  name: "Users",
  components: { ActionTemplate },
  data() {
    return {
      actions: null as Action[] | null,
    };
  },
  mounted() {
    this.fetchActions();
  },
  methods: {
    async fetchActions() {
      this.actions = [];
      this.actions = await fetchRecentActions();
    },
  },
});
