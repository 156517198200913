
import { ExpandedUser } from "@/services/adminService";
import CheckBox from "@/components/CheckBox.vue";
import date from "@/utils/date";
import AvatarImage from "@/components/AvatarImage.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "UserTemplate",
  components: { AvatarImage, CheckBox },
  emits: ["click", "checkBoxClick"],
  props: {
    user: {
      type: Object as PropType<ExpandedUser>,
      required: true,
    },
    showCheckBox: {
      typpe: Boolean,
      default: false,
    },
    checkedUsers: {
      type: Array as PropType<ExpandedUser[]>,
    },
    hover: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    checkboxClicked() {
      if (this.disableCheckmark && !this.isChecked) return;
      this.$emit("checkBoxClick", this.user);
    },
    clicked(event: any) {
      if (event.target.closest(".checkbox")) return;
      this.$emit("click");
    },
  },
  computed: {
    friendlyCreated(): any {
      return date(this.user.created);
    },
    isChecked() {
      return !!this.checkedUsers?.find((u) => u.id === this.user.id);
    },
    disableCheckmark() {
      const anyUser = this.checkedUsers?.[0];
      if (!anyUser) return false;
      return anyUser.banned !== this.user.banned;
    },
  },
});
