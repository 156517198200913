
import Header from "@/components/Header.vue";
import Overview from "./overview/Overview.vue";
import explorePages from "@/utils/adminPanelPages.json";
import { TabsModule } from "@/store/modules/tabs";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ExploreArea",
  components: { Header, Overview },
  computed: {
    page(): any {
      return explorePages[this.$route.params.tab as string];
    },
  },
  watch: {
    page: {
      handler: "onPageChanged",
    },
  },
  mounted() {
    if (!this.page) {
      this.$router.replace("/app/admin-panel/overview");
      return;
    }
    TabsModule.setCurrentTab({
      icon: "security",
      name: "Admin Panel " + this.page.name,
    });
  },
  methods: {
    onPageChanged() {
      if (!this.page) return;
      TabsModule.setCurrentTab({
        icon: "security",
        name: "Admin Panel " + this.page.name,
      });
    },
  },
});
