
import date from "@/utils/date";
import { Action } from "@/services/adminService";
import CustomButton from "@/components/CustomButton.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ActionTemplate",
  components: { CustomButton },
  props: {
    action: {
      type: Object as PropType<Action>,
      required: true,
    },
  },
  computed: {
    friendlyDate(): any {
      return date(this.action.date, localStorage["timeFormat"]);
    },
    actionDetails(): any {
      if (this.action.action === "SUSPEND_USER") {
        return `<strong>${
          this.action.admin.username
        }</strong> suspended <strong>${
          this.action.user?.username
        }</strong> for <strong>${
          this.action.reason || "Not specified."
        }</strong>`;
      }
      if (this.action.action === "UNSUSPEND_USER") {
        return `<strong>${this.action.admin.username}</strong> unsuspended <strong>${this.action.user?.username}</strong>`;
      }
      if (this.action.action === "BAN_IP") {
        return `<strong>${this.action.admin.username}</strong> IP banned <strong>${this.action.bannedIP}</strong> for 5 days.`;
      }
      if (this.action.action === "UNBAN_IP") {
        return `<strong>${this.action.admin.username}</strong> removed IP Ban <strong>${this.action.bannedIP}</strong>`;
      }
      return `Unknown action: ${this.action.action}`;
    },
  },
});
