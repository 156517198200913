
import { fetchStats } from "@/services/adminService";

import { defineComponent } from "vue";
export default defineComponent({
  name: "StatsPanel",
  data() {
    return {
      userCount: null as string | null,
      serverCount: null as string | null,
      messageCount: null as string | null,
    };
  },
  async mounted() {
    const stats = await fetchStats();
    this.userCount = stats.userCount.toLocaleString();
    this.serverCount = stats.serverCount.toLocaleString();
    this.messageCount = stats.messageCount.toLocaleString();
  },
});
