
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["click"],
  name: "CustomInput",
  props: {
    name: {
      type: String,
      required: false,
    },
    alert: {
      type: Boolean,
      required: false,
    },
    warn: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    valid: {
      type: Boolean,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconPos: {
      type: String,
      required: false,
    },
    iconSize: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
});
